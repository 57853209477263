/* eslint-disable @next/next/next-script-for-ga */
import { useEffect, useState } from 'react'
import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'

// Header and Footer
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import { Container } from 'react-bootstrap'

import { appWithTranslation } from 'next-i18next'

import { config } from '@fortawesome/fontawesome-svg-core'

config.autoAddCss = false

import UserContext from '../UserContext'

function MyApp({ Component, pageProps }: AppProps) {
	let initialState: any = null
	if (typeof window !== 'undefined' && window.localStorage.getItem('profile') !== null) {
		initialState = JSON.parse(window.localStorage.getItem('profile') as string)
	}
	const [user, setUser] = useState(initialState)

	// const getRespectiveMeasurementId = (): string => {
	// 	if (process.env.NEXT_PUBLIC_SITE_NAME as string === 'findjobs') return 'G-L45ZPFB6FC'
	// 	else if (process.env.NEXT_PUBLIC_SITE_NAME as string === 'ntuc') return 'G-8ESCVQECFY'
	// 	else return ''
	// }

	const FAVICON = process.env.NEXT_PUBLIC_SITE_FAVICON as string
	const noNavRoute = ['/privacy-policy', '/terms-of-use', '/tpy-east']
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const landing = ['/', '/app', '/employer', '/jobs']

	const router = useRouter()

	useEffect(() => {
		const checkLogout = () => {
			const now = dayjs()
			// const loginTime = JSON.parse(localStorage.getItem('login-date'))
			const loginTime = localStorage.getItem('login-date') !== null ? JSON.parse(localStorage.getItem('login-date') as string) : null
			if(loginTime !== null && now.diff(loginTime) > 604800000) {
				router.push('/logout?redirect=' + router.pathname)
			}
		}
		checkLogout()

		const checkLanding = () => {
			if (landing.includes(router.pathname)) {
				if (parseInt(process.env.NEXT_PUBLIC_SHOW_HOME_PAGE as string) != 1) {
					window.location.href = '/browse-jobs'
				}
			}
		}
		checkLanding()

		const checkNoLogin = () => {
			const authenticatedArr = [
				'/applications', '/profile'
			]
			// check if user is not logged in(On protected route)
			if (user === null && authenticatedArr.includes(router.pathname)) {
				if (typeof window !== 'undefined') {
					router.push('/login').catch(() => null)
				}
			} else if (user !== null && (user.email === '' || user.email === null) && router.pathname !== '/register') {
				if (typeof window !== 'undefined') {
					window.location.href = '/register'
				}
			}
		}
		checkNoLogin()
	}, [router, landing, user])

	if (noNavRoute.includes(router.pathname)) {
		return (
			<>
				<Head>
					<title>Find Jobs Easily</title>
					<link rel='shortcut icon' href={FAVICON} />
					<meta name='msvalidate.01' content='D8CDD2E5673E83EE6BF5C1C24A2E6F3C' />
					<meta name='facebook-domain-verification' content='n27wjx35l29wscysknwvqpao9a2hsx' />
					<script async src='https://www.googletagmanager.com/gtag/js?id=G-L45ZPFB6FC' />
					{process.env.NODE_ENV !== 'production' && <meta name='robots' content='noindex' />}

					<script id='gtag'
						dangerouslySetInnerHTML={{
							__html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-L45ZPFB6FC');
              `
						}}
					/>
				</Head>

				{/* <!-- Hotjar Tracking Code for https://findjobs.com.sg --> */}
				{/* <Script id='hotjar' dangerouslySetInnerHTML={{
					__html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2303350,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `
				}}
				/> */}
				{/* Facebook */}
				<Script id='facebook' dangerouslySetInnerHTML={{
					__html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '338401023193507');
              fbq('track', 'PageView');
            `
				}}
				/>
				<noscript id='fb' dangerouslySetInnerHTML={{
					__html: `
              <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=338401023193507&ev=PageView&noscript=1" />
            `
				}}
				/>
				<Component {...pageProps} />
			</>
		)
	} else {
		return (
			<>
				<Head>
					<title>Find Jobs Easily</title>
					<link rel='shortcut icon' href={FAVICON} />
					<meta name='msvalidate.01' content='D8CDD2E5673E83EE6BF5C1C24A2E6F3C' />
					<meta name='facebook-domain-verification' content='n27wjx35l29wscysknwvqpao9a2hsx' />
					<script async src='https://www.googletagmanager.com/gtag/js?id=G-L45ZPFB6FC' />
					{process.env.NODE_ENV !== 'production' && <meta name='robots' content='noindex' />}
					<script
						dangerouslySetInnerHTML={{
							__html: `
									window.dataLayer = window.dataLayer || [];
									function gtag(){dataLayer.push(arguments);}
									gtag('js', new Date());
									gtag('config', 'G-L45ZPFB6FC');
								`
						}}
						id='gtag'
					/>
				</Head>
				{/* <Script src='https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js' integrity='sha384-GNFwBvfVxBkLMJpYMOABq3c+d3KnQxudP/mGPkzpZSTYykLBNsZEnG2D9G/X/+7D' crossOrigin='anonymous' async></Script> */}

				{/* <!-- Hotjar Tracking Code for https://findjobs.com.sg --> */}
				{/* <Script dangerouslySetInnerHTML={{
					__html: `
								(function(h,o,t,j,a,r){
									h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
									h._hjSettings={hjid:2303350,hjsv:6};
									a=o.getElementsByTagName('head')[0];
									r=o.createElement('script');r.async=1;
									r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
									a.appendChild(r);
								})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
							`
				}}
				id='hotjar'
				/> */}
				{/* Facebook */}
				<Script dangerouslySetInnerHTML={{
					__html: `
								!function(f,b,e,v,n,t,s)
								{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
								n.callMethod.apply(n,arguments):n.queue.push(arguments)};
								if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
								n.queue=[];t=b.createElement(e);t.async=!0;
								t.src=v;s=b.getElementsByTagName(e)[0];
								s.parentNode.insertBefore(t,s)}(window, document,'script',
								'https://connect.facebook.net/en_US/fbevents.js');
								fbq('init', '338401023193507');
								fbq('track', 'PageView');
							`
				}}
				id='facebook'
				/>
				<noscript dangerouslySetInnerHTML={{
					__html: `
								<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=338401023193507&ev=PageView&noscript=1" />
							`
				}}
				/>
				<Navbar />
				{
					landing.includes(router.pathname) ?
						<Component {...pageProps} />
						:
						<Container className='py-4'>
							<UserContext.Provider value={{ user, setUser }}>
								<Component {...pageProps} />
							</UserContext.Provider>
						</Container>
				}
				<Footer />
			</>
		)
	}
}

export default appWithTranslation(MyApp)